import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"


const CustomGuitarNecks = () => {
  return (
    <PageLayout>
      <SEO
        title="Custom Guitar Necks | Your Custom Build | King Electric Guitars"
        ogDescription="The custom guitar necks I build are by hand. I source the wood, hand select each piece, and do all the work here in my little shop in Griffin, GA. Let's build your dream guitar!"
      />
  <TextBlock
    textPadded
    textLeft={
      <div>
        <h1>Custom Guitar Necks</h1>
        <p>
          All my guitars have custom necks handmade by me to your specifications. I don't buy stock necks. I don't buy necks from a ghost builder. I source the wood, hand select each piece, and do all the work here in my little shop in Griffin, GA. The neck is one of the most critical parts of a custom guitar and can be a big part of what makes them special. After all, the neck is your interface with the guitar. It's a big part of how you interact with the guitar and make it an extension of yourself. And, there are a lot of choices that go into the neck design. I go through each option with you and guide you through the process.
        </p>
        <FormWrapper
          modal
          buttonProps={{
            content: "Reach Out. Let's Talk!",
            size: "tiny",
            icon: "chat",
          }}
        >
          <RequestMoreInfo />
        </FormWrapper>
      </div>
    }
    textRight={
      <div>
        <img src="/images/custom-guitar-necks.jpg" alt="Custom Guitar Necks" />
      </div>
    }
  />
  <div className="section textured">
    <TextBlock
      textPadded
      textLeft={
        <div>
          <img src="/images/custom-guitar-neck-woods.jpg" alt="Wood Selection for Custom Guitar Necks" />
        </div>
      }
      textRight={
        <div>
          <h2>Custom Guitar Neck Wood Selection</h2>
          <p>
            I choose woods that complement the look of my <a href="/guitar-body/">guitar bodies</a>. I always use some kind of good hardwood - a Maple neck, a Mahogany neck, or even Sapele neck. You can use a lot of different types of wood as long as it's good hardwood. 
          </p>
          <p>
            And if you are a fan of Rosewood fingerboards, I can do that as well. though we don't have to limit the wood to Rosewood. There is a variety of options we can use that you'd never find in a standard guitar line.
          </p>
        </div>
      }
    />
  </div>
  <TextBlock
    textPadded
    textLeft={
      <div>
        <h2>Guitar Neck Construction</h2>
        <p>
          When building a neck, I'll make a two-piece or three-piece neck. They are stronger than a one-piece neck. I make my necks out of a vertical grained wood. So I'll usually cut a piece into half or thirds, flip it, and sideways glue them all together.
        </p>
        <h3>Dual Action Truss Rod</h3>
        <p>
          I use <a href="https://www.stewmac.com/luthier-tools-and-supplies/materials/truss-rods/adjustable-truss-rods/slotted-nut-hot-rod-truss-rod/" target="_blank" rel="noreferrer">dual-action truss rods</a>. That way, if anything ever happens to the neck 50 years from now it will be adjustable. You can make the neck have a back bow or an up bow. It's designed to do both. It's only a minor increase in expense for a lot more functionality and will give the guitar a very long life.
        </p>
        <h3>Carbon-Fiber Reinforcement Rods</h3>
        <p>
          I also use carbon-fiber reinforcement rods on all my stock models. This add regitity to the neck and makes the truss rods job a bit easier because it isn't dealing with all the string tension.
        </p>
      </div>
    }
    textRight={
      <div>
        <img src="/images/custom-guitar-neck-construction.jpg" alt="Custom Guitar Neck Construction" />
      </div>
    }
  />
  <TextBlock
    textPadded
    textLeft={
      <div>
        <img src="/images/custom-guitar-neck-hand-cut-fretboards.jpg" alt="Custom Guitar Neck Hand-Cut Fretboards" />
      </div>
    }
    textRight={
      <div>
        <h2>Hand-Cut Fretboards</h2>
        <p>
          I cut my own fretboards, cut the radiuses, do my fretwork, cut the nut, etc. I don't have a CNC machine. I'm doing everything by hand. Hand tools and small power tools. No kind of robot help whatsoever. That allows me to customize each neck to the feel and contour that works best for each guitar and player.
        </p>
        <h3>Fingerboard Radius</h3>
        <p>
          One of the things we will discuss is what fretboard radius you want. When I'm building for myself - or building a stock model of mine - I'm going with a 12-inch radius. That's a radius a lot of players prefer and people are used to it. Gibson uses it and  Fender uses it some. And it's not far from the more common modern Fender 10-inch radius. But if you want a more vintage 7.25-inch or 9.5-inch Fender style radius, we can do that as well. Or, if you are a fast player, I can even do a 16-inch radius.
        </p>
      </div>
    }
  />
  <PageWrapper color="grey">
    <TextBlock
      textPadded
      textLeft={
        <div>
          <img src="/images/custom-guitar-neck-contouring.jpg" alt="Contouring The Back of the Guitar Neck" />
        </div>
      }
      textRight={
        <div>
          <h2>Contouring The Back of the Neck</h2>
          <p>
            A big part of how the guitar feels has to do with how I contour the back neck shape. You may have heard terms like U-shaped, V-shaped, C-shaped, and D-shaped. Players with big hands might like the feel of a U-shaped neck, the chunkiest of the shapes. In contrast, D-shaped necks are the thinnest, which fast players like. But there are limitations on how thin I can go. C-shaped necks are most common, and what most people are used to. So, that's what I usually do.
          </p>
          <h3>Symmetrical or Asymmetrical Necks</h3>
          <p>
            Most guitar neck shapes are symmetrical. This means is has centered contouring and will feel the same up and down the neck. But, some players like the feel of an asymmetrical neck. These can be thicker to the top or bottom of the neck. Or, we can angle contouring to follow the position of your hand as you move up the neck. Since every neck is handmade by me, I can contour each neck to be exactly what you want it to be.
          </p>
          
        </div>
      }
    />
  </PageWrapper>
  <TextBlock
    textPadded
    textLeft={
      <div>
        <h2>Guiding Your Selections</h2>
        <p>
          Some custom guitar customers know exactly what they want with radius and contouring. But many have no idea. And that's okay.  I've learned that a lot of people own guitars and don't know the design choices. They don't know what it is they like about it. They just know they like it. You can bring your current favorite guitar to me. I can take neck measurements and recreate that on the custom build. Or, you can come down and play several of my designs and see which feels best to you.
        </p>
        <FormWrapper
          modal
          buttonProps={{
            content: "Reach Out. Let's Talk!",
            size: "tiny",
            icon: "chat",
          }}
        >
          <RequestMoreInfo />
        </FormWrapper>
      </div>
    }
    textRight={
      <div>                
        <img src="/images/custom-guitar-neck-design.jpg" alt="Custom Guitar Neck Design - Guiding Your Selections" />
      </div>
    }
  />
  <TextBlock
    textPadded
    textLeft={
      <div>
        <img src="/images/custom-guitar-neck-fretboard-inlays.jpg" alt="Fretboard Inlays" />
      </div>
    }
    textRight={
      <div>
        <h2>Fretboard Inlays</h2>
        <p>
          With each design, I try to do something special with the inlays. If it's a maple neck with a darker wood body, I can bring over the body wood for the inlays to tie it together. On my own models, I like to left justify the fretboard inlays. But I'm open to whatever ideas you have. If I can do it, I will. Though the more ornate the design, the higher the cost. It takes a lot of time to do some inlay patterns.
        </p>
      </div>
    }
  />
  <TextBlock
    textPadded
    textLeft={
      <div>
        <h3>Glow in the Dark</h3>
        <p>
          On the side of the neck, I can use luminescent powder so your fret markers glow in the dark. This can sometimes be a big help on dark stages. But honestly, it just looks cool. 
        </p>
      </div>
    }
    textRight={
      <div>                
        <img src="/images/custom-guitar-neck-glowing-inlays.jpg" alt="Glow in the Dark Guitar Neck Inlays" />
      </div>
    }
  />
  <div className="section textured">
    <TextBlock
      textPadded
      textLeft={
        <div>
          <img src="/images/custom-guitar-neck-pocket.jpg" alt="Custom Guitar Neck Pocket" />
        </div>
      }
      textRight={
        <div>
          <h2>Custom Neck Pocket</h2>
          <p>
            One thing that always drove me crazy about the T-style, bolt-on necks was the thickness of the body at the neck pocket. It gets in the way when you play up on the neck. I add body contouring and a modified neck pocket that gives you better playability. That's standard on all my guitars unless you ask for it to be flat. But, this contouring will be something that most people will find very comfortable. And it's something that you're not going to get on an out-of-the-box guitar - or even a custom shop model - from a big manufacturer.
          </p>
          <p>
            All my bolt-on necks have threaded inserts in the neck heel and are attached with stainless steel machine screws.
          </p>
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <h2>King Electric Guitars Headstock</h2>
          <p>
            The thing that usually kills the look of a custom guitar for me is the headstock. There are so many ugly headstock designs. So, I've spent a lot of time designing my current headstock. It's already gone through a few iterations, but it's starting to look right to me. It's familiar and different at the same time. It has contouring detail you don't often see on headstocks. And, it even looks great upside down!
          </p>
        </div>
      }
      textRight={
        <div>
          <img src="/images/custom-guitar-neck-headstock.jpg" alt="King Electric Guitars Headstock" />
        </div>
      }
    />
    <TextBlock
      textPadded
      textLeft={
        <div>
          <img src="/images/custom-guitar-neck-tuners.jpg" alt="Custom Guitar Locking Tuners" />
        </div>
      }
      textRight={
        <div>
          <h2>Locking Tuners</h2>
          <p>
            I can use any tuning system you prefer, but I really dig these locking tuners from Hipshot - and I have been using them on all my stock guitars.
          </p>
        </div>
      }
    />
  </div>
  <SingleColumnBlock
    textPadded
    text={
      <div>
        <h4>Get in touch. Let's talk about building your dream guitar!</h4>
        <RequestMoreInfo />
      </div>
    }
  />
    </PageLayout>
  )
}

export default CustomGuitarNecks
